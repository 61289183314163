import type { InputProps } from "@chakra-ui/react";
import { chakra, forwardRef } from "@chakra-ui/react";
import { Field as _Field } from "formik";

export interface FieldProps extends InputProps {}

// Wrap component in Chakra Factory, allows us to pass style props
const InputField = chakra(_Field, {
    shouldForwardProp: (prop) =>
        [
            "name",
            "innerRef",
            "validate",
            "render",
            "component",
            "as",
            "type",
            "isDisabled",
            "placeholder",
            "aria-labelledby",
            "onBlur",
        ].includes(prop),
});

export const Field = forwardRef<FieldProps, "input">(({ children, ...props }, ref) => {
    return (
        <InputField ref={ref} {...props} py={["2.5", "2.8"]} px={["3", "4"]}>
            {children}
        </InputField>
    );
});
