import { forwardRef, useBreakpointValue } from "@chakra-ui/react";
import type { ContentfulImageParams } from "@delicious-simplicity/next-image-contentful-loader";
import { contentfulLoader } from "@delicious-simplicity/next-image-contentful-loader";
import { isEmpty } from "lodash-es";
import type { ImageProps as _NextImageProps, ImageLoaderProps, StaticImageData } from "next/image";
import _NextImage from "next/image";
import breakpoints from "../../../design-tokens/09.breakpoints/breakpoints";
import { Box } from "../../layout/box/Box";
import type { FastlyImageOptimizerParams } from "./aprimo-loader";
import { aprimoLoader } from "./aprimo-loader";
import { FallbackImage } from "./FallbackImage";
import { useState } from "react";
import { useIsomorphicLayoutEffect } from "../../../hooks/useIsomorphicLayoutEffect";
import { PRODUCT_IMAGE_PLACEHOLDER_URL } from "./imagePaths";

export interface StaticRequire {
    default: StaticImageData;
}

export { contentfulLoader };

export type StaticImport = StaticRequire | StaticImageData;

export interface NextImageProps
    // omit the width / height props from Chakra style props - as these props have a special meaning in Next Image

    // Pick the Next Image props from their interface - if we just naively extend there will be type clashes because they omit certain HTMLImage props
    extends Omit<_NextImageProps, "sizes"> {
    /**
     * The image sizes to use when loading the image from contentful
     * should be a percent of viewport width, you can also use the value with wv
     */
    sizes?: string[];
    /**
     * Image Optimizer params for @fastly
     */
    fastlyImageParams?: FastlyImageOptimizerParams;
    objectFit?: string;
}

export const isContentfulImage = (src: string | StaticImport): boolean =>
    typeof src === "string" && src?.includes("images.ctfassets.net");

export const isAprimoImage = (src: string | StaticImport): boolean =>
    typeof src === "string" && src?.includes("aprimocdn");

export const isPlaceholderImage = (src: string): boolean =>
    !src || (typeof src === "string" && src?.includes(PRODUCT_IMAGE_PLACEHOLDER_URL));

export const protocolRelativeToAbsoluteUrl = (url: string) => {
    if (url?.startsWith("//")) {
        return `https:${url}`;
    }
    return url;
};

export const isStaticImage = (src: string | StaticImport): boolean => {
    if (isEmpty(src) || typeof src === "string") {
        return false;
    }

    return !!("default" in src || "src" in src);
};

const useImageFallback = (src: string | StaticImport) => {
    const [showFallback, setShowFallback] = useState(src === PRODUCT_IMAGE_PLACEHOLDER_URL);

    const onLoadingComplete = (result: { naturalWidth: number; naturalHeight: number }) => {
        // If natural width is 0 that means the image is broken
        if (result.naturalWidth === 0) {
            setShowFallback(true);
        }
    };

    const onError = () => {
        setShowFallback(true);
    };

    useIsomorphicLayoutEffect(() => {
        // If img url is not coming from contentful / aprimo / fs, we show a fallback image
        if (isContentfulImage(src) || isAprimoImage(src) || isStaticImage(src)) {
            setShowFallback(false);
        }
    }, [src]);

    return { showFallback, onLoadingComplete, onError };
};

export const createNextImageSizes = (approximateImageSizesForBreakpoints?: string[]): number[] => {
    const [_, ...remainingBreakpoints] = Object.values(breakpoints);
    if (!approximateImageSizesForBreakpoints) {
        return remainingBreakpoints.map((it) => parseInt(it));
    }

    const sizes: string[] = [];
    remainingBreakpoints.forEach((size, index) => {
        const breakpointValue = parseInt(size);
        const approximateImageSizeForBreakpoint: number = parseInt(
            approximateImageSizesForBreakpoints[index] ||
                approximateImageSizesForBreakpoints[approximateImageSizesForBreakpoints.length - 1]
        );

        if (approximateImageSizesForBreakpoints.length) {
            sizes.push(((breakpointValue * approximateImageSizeForBreakpoint) / 100).toFixed(0));
        } else {
            sizes.push(breakpointValue.toFixed(0));
        }
    });

    return sizes.map(Number).map((item) => (item > 4000 ? 4000 : item));
};

export const contentfulLoaderDefaultProps: ContentfulImageParams = {
    fm: "avif",
    q: 95,
};

export const NextImage = forwardRef((props, ref) => {
    const {
        alt = "",
        src,
        width,
        height,
        loader,
        onError,
        quality,
        priority,
        loading,
        lazyRoot,
        sizes,
        style,
        lazyBoundary,
        placeholder,
        blurDataURL,
        unoptimized,
        onLoadingComplete,
        // These are the props for the fastly image optimizer
        fastlyImageParams,
        fill,
        objectFit = "contain",
        // The rest are set on <Box/>
        ...rest
    } = props;

    const parsedSrc = protocolRelativeToAbsoluteUrl(src as string);

    const {
        showFallback,
        onLoadingComplete: onFallbackLoadingComplete,
        onError: onFallbackError,
    } = useImageFallback(parsedSrc);

    const imageSize =
        useBreakpointValue(createNextImageSizes(sizes), { fallback: "md" }) ??
        createNextImageSizes(sizes)?.[0] ??
        1440;

    const imgProps = {
        alt,
        src: parsedSrc,
        width: !fill ? (width ?? imageSize) : undefined,
        height: !fill ? (height ?? imageSize) : undefined,
        //sizes: nextSizes,
        style,
        loader,
        loading,
        onError,
        quality,
        priority,
        lazyRoot,
        blurDataURL,
        placeholder,
        unoptimized,
        lazyBoundary,
        onLoadingComplete,
    };

    const defaultLoader = (props: ImageLoaderProps) =>
        isAprimoImage(parsedSrc)
            ? aprimoLoader(
                  {
                      src: parsedSrc,
                      width: imageSize,
                      quality: props.quality,
                  },
                  fastlyImageParams
              )
            : contentfulLoader(
                  {
                      src: parsedSrc,
                      width: imageSize,
                  },
                  {
                      fm: contentfulLoaderDefaultProps.fm,
                      q: props.quality ?? contentfulLoaderDefaultProps.q,
                  }
              );

    return (
        <>
            {showFallback || isPlaceholderImage(parsedSrc) ? (
                <FallbackImage
                    aria-label={imgProps.alt}
                    width={width}
                    aspectRatio={["4/5"]}
                    backgroundColor={props.backgroundColor}
                />
            ) : (
                <Box
                    position="relative"
                    overflow="hidden"
                    height="100%"
                    width="100%"
                    ref={ref}
                    {...rest}
                >
                    {parsedSrc && (
                        <_NextImage
                            {...imgProps}
                            fill={!width && !height && fill}
                            sizes={fill ? sizes : undefined}
                            style={{ objectFit: objectFit, bottom: 0 }}
                            onLoad={onLoadingComplete || onFallbackLoadingComplete}
                            onError={onError || onFallbackError}
                            loader={loader || defaultLoader}
                        />
                    )}
                </Box>
            )}
        </>
    );
});
