import { useGlobalModalContext } from "@components/GlobalModalProvider";
import { CommonCMS } from "@lib/constants/contentful";
import { useMicrocopy } from "@lib/contentful/microcopy/MicrocopyContext";
import { Text } from "@ui/components/content/text/Text";
import { Box } from "@ui/components/layout/box/Box";
import { defaultUnderlineStyle } from "@ui/css/common";
import Modal from "../Modal";
import ModalContent from "../ModalContent";
import { CONTACT_FORM_SUBMITTED } from "@e2e/helpers/contact-form/constants";

export const ContactFormSubmitted = () => {
    const { hideModal } = useGlobalModalContext();
    const { get: getMicrocopy } = useMicrocopy();

    return (
        <Modal closeOnOverlayClick={true} onClose={() => hideModal()}>
            <ModalContent
                header={getMicrocopy(CommonCMS.global, CommonCMS.contactForm.messageSent)}
            >
                <Box data-testid={CONTACT_FORM_SUBMITTED}>
                    <Text
                        sx={{
                            "& button": {
                                color: "black",
                                ...defaultUnderlineStyle,
                            },
                        }}
                        color="black"
                        fontSize={[
                            "mobileBodyTextSmall",
                            "mobileBodyTextSmall",
                            "desktopBodyTextSmall",
                        ]}
                    >
                        {getMicrocopy(CommonCMS.global, CommonCMS.contactForm.thankYouContact)}
                    </Text>
                </Box>
            </ModalContent>
        </Modal>
    );
};
