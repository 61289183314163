import { object, string } from "yup";
import { emailRegex } from "../regex";

export interface LogInHeaders {
    requiredField: string;
    email: string;
    maxCharacters?: string;
}

export function getEmailValidationSchema(headers: LogInHeaders) {
    return object().shape({
        email: string()
            .required(headers.requiredField)
            .email(headers.email)
            .max(35, headers.maxCharacters)
            .concat(checkValidEmailDomain(headers.email)),
    });
}

export function getLogInValidationSchema(headers: LogInHeaders) {
    return object().shape({
        email: string()
            .trim()
            .required(headers.requiredField)
            .matches(emailRegex, headers.email)
            .email(headers.email)
            .max(35, headers.maxCharacters)
            .concat(checkValidEmailDomain(headers.email)),
        password: string().required(headers.requiredField),
    });
}

export const checkValidEmailDomain = (message) => {
    return string().test({
        name: "no-symbol-in-domain",
        exclusive: true,
        message: message,
        test: function (value) {
            if (!value) {
                return true;
            }

            const atIndex = value.indexOf("@");
            if (atIndex !== -1) {
                const domain = value.slice(atIndex + 1);
                return /^[a-zA-Z0-9.-]+$/.test(domain);
            }

            return true;
        },
    });
};
