export const INPUT_PHONE = 'input[name="phone"]';
export const REASON_SELECT = 'select[name="myQuestion"]';
export const MY_QUESTION = 'textarea[name="comment"]';

export const ARIA_INVALID = "aria-invalid";

export const CONTACT_FORM_SUBMIT_BUTTON = "ContactForm-button";
export const CONTACT_FORM_LINK = "contactForm-link";
export const CONTACT_FORM = "ContactForm";
export const CONTACT_FORM_SUBMITTED = "ContactFormSubmitted";
